<template>
  <v-main>
  <v-container id="htline-main">
    <v-row>
      <v-col>
        <p class="text-h5">友だち登録</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p class="text-body">こちらのQRコードをスマホのカメラで読み取ってください。LINEがインストールされている場合、自動でLINEが起動し、友だち登録の画面が表示されます。</p>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="3">
        <v-img :src="require('@/assets/qr_to_friend.png')" />
      </v-col>
    </v-row>
    <v-row>
      <v-card flat>
        <v-card-title>動画</v-card-title>
        <v-card-text>
          Rice SCOUTER LINE edition を使っている様子の動画です。ご覧ください。
        </v-card-text>
      </v-card>
    </v-row>
    <v-row justify="center">
      <youtube video-id="s1vUdN57yAw" />
    </v-row>
  </v-container>    
  </v-main>
</template>

<script>
export default {
  name: 'HowToLine',
  components: {
  },
}
</script>

<style lang="css">
#htline-main {
  margin-bottom: 10vh;
}
</style>
